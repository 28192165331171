import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import Img from "gatsby-image"

export const query = graphql`
    query GalleryQuery($slug: String!) {
        strapiGallery(slug: { eq: $slug }) {
            title
            publishedAt
            images {
                localFile{
                    publicURL
                    childImageSharp {
                        fixed(width: 500, height: 400) {
                            src
                        }
                    }
                }
            }
        }
    }
`

const Gallery = ({ data }) => {
  const album = data.strapiGallery
  const seo = {
    metaTitle: album.title,
    metaDescription: album.description,
    article: true
  }

  return (
    <Layout seo={seo}>
      <div className="uk-container">
        <h4 className="uk-heading-line uk-text-bold"><span>{album.title}</span></h4>
        <div className="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center uk-grid"
             uk-lightbox="animation: slide">
          {album.images.map((image, i) => {
            return (
              <div className="uk-card uk-card-default uk-card-body">
                <div>
                  <a className="uk-inline" href={image.localFile.publicURL}>
                    <Img
                      fixed={image.localFile.childImageSharp.fixed}
                      imgStyle={{ position: "static" }}
                    />
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Gallery